
import { useEffect } from "react"
import { navigate } from "gatsby"

const ProductscategoryPage = () => {
	useEffect(() => {
		navigate("/products")
	}, [])

	return null;
}

export default ProductscategoryPage
